import React, {createRef } from 'react';
import { Button, FormControlLabel, Grid, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { firebase_app } from '../FirebaseInit';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import seppFaq from '../assets/img/sepp-faq.png'
import seppFaqSmall from '../assets/img/sepp-faq-small.png'

import App from '../App';

interface FaqPanelProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}

class FaqPanel extends React.Component<FaqPanelProps> {
   
    state = {


    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;

    constructor(props: FaqPanelProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
    }
 
    componentDidMount = () => { 

        this._processProps();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){


        }

    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: FaqPanelProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;

            //this._initChat(currentUser);
        }
    }

    onClick_Next = (event: any) => {
        

        //event.preventDefault();

    }


    render = () => {
        return (
        <Box className={"minHeight-small"} style={{padding: "0"}}>


            <Grid container spacing={2} >
                <Grid item xs={12} md={4}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img className={"hideSmall"} style={{ maxHeight: "800px" }} src={seppFaq} />
                        <img className={"hideLarge"} style={{ maxHeight: "300px" }} src={seppFaqSmall} />
                    </div>
                </Grid>

                <Grid item xs={12} md={8}>
                    <div style={{padding: "8px"}}>
                        <Typography className='faqHeader' sx={{ fontSize: 24 }} color="primary" gutterBottom>
                            FAQ <b>DIE WICHTIGSTEN FRAGEN</b>
                        </Typography> 

                        <div style={{paddingLeft: "8px"}}>
                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Was macht SEPP?
                            </Typography> 
                            <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
                                Ich kümmere mich um deinen Versicherungsschaden. Von der Erstmeldung an die Versicherung bis zur Reparaturvergabe. Du musst dich nur noch um die Terminvereinbarung mit dem Handwerker oder der Werkstatt kümmern.
                            </Typography>
                            <hr color='#87b1b5'></hr>


                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                            Warum mit SEPP arbeiten?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                            Es gibt Fristen bei der Versicherungsmeldung die du nicht versäumen darfst. Deshalb biete ich die schnellste und einfachste Möglichkeit den Schaden bei mir zu deponieren. Alles weitere erledige ich für dich.
                            </Typography>
                            <hr color='#87b1b5'></hr>

                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Wie funktioniert SEPP?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                                Du gibst deine Daten zu deinem Schadenfall auf meiner Website ein und ich übernehme die weitere Korrespondenz mit der Versicherung. Ich benachrichtige dich über die einzelnen Schritte während der Schadenabwicklung. Auf sofort notwendige Unterlagen mache ich dich aufmerksam und wenn Unterlagen fehlen oder zusätzliche Unterlagen benötigt werden sag ich dir Bescheid, damit dein Schaden schnellst-möglich abgearbeitet werden kann. Über mein Partnernetzwerk erhältst du dann schnelle und professionelle Hilfe.
                            </Typography>
                            <hr color='#87b1b5'></hr>

                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Was kostet die Zusammenarbeit mit SEPP?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                                <b>Nichts.</b> Durch die Nutzung des Handwerker- und Werkstätten Netzwerks genießt du eine schnellst-mögliche Schadensregulierung.
                            </Typography>
                            <Typography sx={{ fontSize: 12, marginTop: 2 }} color="secondary" gutterBottom>
                            Zusatzleistungen wie der Erinnerungsservice für die Beauftragung eines Gutachters zur Feststellung der Dauerinvalidität nach einem Personenschaden in der <b>Unfallversicherung</b>, unter Einhaltung der vorgegebenen Fristen, werden 10% der Entschädigungsleistung als Bearbeitungshonorar verrechnet.
                            </Typography>
                            <Typography sx={{ fontSize: 12, marginTop: 2 }} color="secondary" gutterBottom>
                            <b>Beispiel Unfallversicherungsschaden</b>: Du hast dir z.B. einen Bänderriss im Knöchel zugezogen. Nachdem du alle notwendigen Heilungsmaßnahmen durchgeführt hast kannst du innerhalb 15. Monaten ( längere Fristen möglich) einen Dauerinvaliditätsanspruch stellen, wo dann durch ein Gutachten bei einen Arzt, die Feststellung der Bewegungseinschränkung gemacht wird. Versäumst du die Frist hast du keinen Leistungsanspruch mehr. Hier mache ich dich aufmerksam, dass du zu deinem Anspruch kommst.
                            </Typography>
                            <hr color='#87b1b5'></hr>


                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Gibt es eine SEPP APP?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                                Ja. Du kannst dir SEPP von unserer Website als Icon auf den Homescreen ablegen.
                            </Typography>
                            <hr color='#87b1b5'></hr>


                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Wie verdient Sepp sein Geld?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                            Durch die Handwerker- und Werkstättenvermittlung werden Honorare an SEPP bezahlt, sodass dir als Kunde keine Kosten entstehen.
                            </Typography>
                            <hr color='#87b1b5'></hr>

                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Was ist wenn mein Schaden abgelehnt wird?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                                Ich habe ein Netzwerk aus Rechtsanwälten und Versicherungsexporten, die deine Ablehnung prüfen und dir Hilfestellung geben können. Diese erreichst du unter 0800 400 440.
                            </Typography>
                            <hr color='#87b1b5'></hr>

                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Wie funktioniert die Unterstützung bei Schadensablehnung?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                                Die Erstberatung ist für dich kostenlos. <br/>
                                Bevor für dich Kosten entstehen, wird das im Vorfeld gemeinsam besprochen und mit dir abgestimmt wie die weitere Vorgehensweise ist.
                            </Typography>
                            <hr color='#87b1b5'></hr>


                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Was kann SEPP noch nicht?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                                Ich kann dir leider keine Krankenversicherungsmeldungen weiterleiten.<br/>
                                Kleinstschäden bis 150 Euro kann ich für dich leider nicht abwickeln. Auf Anfrage mit eingeschränktem Aufwand.<br/><br/>
                                SEPP kennt sich nur in Österreich aus.
                            </Typography>
                            <hr color='#87b1b5'></hr>


                            <Typography sx={{ fontSize: 24, textTransform: "uppercase", fontWeight: "bold" }} color="primary" gutterBottom>
                                Was bedeutet SEPP?
                            </Typography> 
                            <Typography sx={{ fontSize: 16 }} color="secondary" gutterBottom>
                                Ich bin <b>S</b>chnell, <b>E</b>infach, <b>P</b>rofessionell und <b>P</b>roblemlos.
                            </Typography>
                            <hr color='#87b1b5'></hr>
                        </div>
                    </div>

        
                </Grid>
 
            </Grid>


            
   
        </Box>
        );
    }
}

export default FaqPanel;