import React, {createRef } from 'react';
import { Button, FormControlLabel, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Talk from "talkjs";
import { firebase_app } from '../FirebaseInit';



import App from '../App';

interface MessagePanelProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}

/*const BackofficeUser = {
    id: "mwKDsdIs1ya6XEJMin542tXmDWD3",
    name: "SEPP (08:00 - 17:00)",
    email: "sepp@seppsite.com",
    photoUrl: window.location.origin + '/sepp-avatar.png',
    role: "Member",
    info: "seppsite.com Agent",
    welcomeMessage: "Herzlich Willkommen! Wie kann ich dir helfen? :-)"
}*/

const BackofficeUser = {
    id: "9wayPhkvSHbxueAH6ESfzR0klEB2",
    name: "seppside.com",
    email: "sepp@seppside.com",
    photoUrl: window.location.origin + '/sepp-avatar.png',
    role: "Member",
    info: "seppside.com Agent",
    welcomeMessage: "Herzlich Willkommen! Wie kann ich dir helfen? :-)"
}


class MessagePanel extends React.Component<MessagePanelProps> {

   
    state = {

        showLoading_Login: false,
        talkJsSignature: "",
        conversationId: ""
    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;
    talkjsContainer: any;

    constructor(props: MessagePanelProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
        this.talkjsContainer = React.createRef();
    }
 
    componentDidMount = () => { 

        this._processProps();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){


        }

    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: MessagePanelProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  

    _initChat = (firebaseUser: firebase.default.User) => {
        console.log("init chat", firebaseUser.uid);
        /* Retrieve the two users that will participate in the conversation */
        let currentUser = {
            id: firebaseUser.uid,
            name: firebaseUser.email as string,
            email: firebaseUser.email,
            //photoUrl: "https://randomuser.me/api/portraits/men/32.jpg",
            role: "Member",
            info: "...",
            welcomeMessage: "Servus!"
        };

        if(currentUser == null || BackofficeUser == null){return;}

        var getUserSignature = firebase_app.functions().httpsCallable('getUserSignature');       
        
        getUserSignature({  })
        .then((result: any) => {
            // Read result of the Cloud Function.
            //console.log("getUserSignature", result);
            this.setState({talkJsSignature: result.data.signature})


            /* Session initialization code */
            Talk.ready
            .then(() => {
                /* Create the two users that will participate in the conversation */
                const me = new Talk.User(currentUser);
                const other = new Talk.User(BackofficeUser)

                /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
                //if (!window.talkSession) {
                    console.log("talkJsSignature", this.state.talkJsSignature);
                    window.talkSession = new Talk.Session({
                        //appId: 'tK8krHQJ',
                        appId: 'ofdIwanw',
                        me: me,
                        signature: this.state.talkJsSignature
                    });
                //} 
                
                /* Get a conversation ID or create one */
                const conversationId = Talk.oneOnOneId(me, other);
                const conversation = window.talkSession.getOrCreateConversation(conversationId);

                this.setState({conversationId: conversationId});
                

                /* Set participants of the conversations */
                conversation.setParticipant(me);
                conversation.setParticipant(other);

                /* Create and mount chatbox in container */
                let chatbox = window.talkSession.createChatbox(conversation);
                //chatbox.mount(this.talkjsContainer);
                chatbox.mount(this.talkjsContainer.current);
            })            
            .catch(e => console.error(e));
        });
    }

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;

            this._initChat(currentUser);
        }
    }

    render = () => {
        return (
        <Box className={"minHeight-small"} style={{padding: "0"}}>
            {/* <Button variant='outlined' color="inherit" onClick={(event) => {window.talkSession.setDesktopNotificationEnabled(true);}}>enable notifications</Button> */}
            
            <div style={{height: "730px"}} ref={this.talkjsContainer}></div>

   
        </Box>
        );
    }
}

export default MessagePanel;