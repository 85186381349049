import React from 'react';
import { Button, FormControlLabel, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import { firebase_app } from '../FirebaseInit';


interface LoginPanelProps {
  style?: React.CSSProperties;
  balance?: string;
}


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const {
    REACT_APP_REDIRECT_LOGIN_URL
} = process.env;

class LoginPanel extends React.Component<LoginPanelProps> {

    private login_emailTxt: React.RefObject<HTMLInputElement>;

    state = {
        tabValue: 0,
        currentUser: { 
            email: ""
        },
        showLoading_Login: false,
        email: ""
    }
    baseState: any;
    _stateInterval: any;


    constructor(props: LoginPanelProps) {
        super(props);

        this.baseState = this.state;
        this.login_emailTxt = React.createRef();

        // properties

    }

    componentDidMount = async () => { 

        // reinit auth if present
        firebase_app.auth();
        let currentUser = await firebase_app.auth().currentUser;

        //var email = window.localStorage.getItem('emailForSignIn');
        const urlParams = new URLSearchParams(window.location.search);
        var email = urlParams.get('mail')?.toString();
        this.setState({email: email});

        if (firebase_app.auth().isSignInWithEmailLink(window.location.href) && !currentUser) {
            if(email){
                firebase_app
                    .auth()
                    .signInWithEmailLink(email, window.location.href)
                    .then(function(result) {
                        //window.localStorage.removeItem('emailForSignIn');
                    })
                    .catch(function(error) {
                    alert(error);
                    });
            }
        }
        

        this._startStatePolling();
    }

    componentWillUnmount = () => { 


    }

    _startStatePolling = () => {

        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval - 1000);
    }

    componentDidUpdate(props: LoginPanelProps) {
        //this.balance = props.balance;
        //this.setState({balance: this.balance});
    }

    checkState = async () => {          
        this.setState({currentUser: await firebase_app.auth().currentUser});        
    }  

    tabHandleChange = (event: any, newValue: any) => {
        this.setState({tabValue: newValue});
    }
    
    onClick_Logout = async () => {
        this.setState({showLoading_Login: true});
        await firebase_app.auth().signOut();
        this.setState({showLoading_Login: false});

        this._startStatePolling();
    }

    /*onClick_TestMail = async () => {
        var sendSeppVerificationEmail = firebase_app.functions().httpsCallable('sendSeppVerificationEmail');   
        sendSeppVerificationEmail({ mail: "mofoda3542@yubua.com"  })
        .then((result: any) => {
            console.log("sendSeppVerificationEmail", result);
        }).catch(error => {
            alert("Da ist etwas schief gelaufen: " + error);
        });



        
    }*/

    onClick_LoginWithLink = async () => {
        if(this.login_emailTxt.current == null){
            return;
        }

        this.setState({showLoading_Login: true});
        var sendSeppVerificationEmail = firebase_app.functions().httpsCallable('sendSeppVerificationEmail');   
        sendSeppVerificationEmail({ mail: this.login_emailTxt.current.value, redirectUrl: REACT_APP_REDIRECT_LOGIN_URL  })
        .then((result: any) => {
            this.setState({showLoading_Login: false});
            console.log("sendSeppVerificationEmail", result);
        }).catch(error => {
            this.setState({showLoading_Login: false});
            alert("Da ist etwas schief gelaufen: " + error);
        });

/*
        //window.localStorage.setItem('emailForSignIn', this.login_emailTxt.current.value);
        actionCodeSettings.url = actionCodeSettings.url + "?mail=" + window.encodeURIComponent(this.state.email);

        this.setState({showLoading_Login: true});
        await firebase_app.auth().sendSignInLinkToEmail(this.login_emailTxt.current.value, actionCodeSettings).catch((error) => {
            window.alert(error);
        });
        this.setState({showLoading_Login: false});

        this._startStatePolling();
*/


    }


    render = () => {
        return (
        <Paper style={{padding: "16px"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={this.state.tabValue} onChange={this.tabHandleChange} aria-label="basic tabs example">
                <Tab label="Einloggen" />
            </Tabs>
            </Box>
            <TabPanel value={this.state.tabValue} index={0}>
                <Typography style={{display: (this.state.currentUser == null)?"none":""}} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Angemeldet als
                </Typography>
                <Typography sx={{ fontSize: 14, marginBottom: 2 }} color="text.primary" gutterBottom>
                    {this.state.currentUser?.email}
                </Typography>

                <div style={{display: (this.state.currentUser == null)?"":"none"}}>
                    <Typography style={{}} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Du hast bereits ein Konto bei SEPP?
                    </Typography>
                    <TextField inputRef={this.login_emailTxt} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} defaultValue={this.state.email} label={"E-Mail"} type="text" fullWidth={true}></TextField>

                    <Button sx={{my: 1}} size="small" disabled={this.state.showLoading_Login} variant="contained" onClick={this.onClick_LoginWithLink} fullWidth={true}>Anmeldelink per E-Mail senden</Button>
                </div>

                <Button sx={{my: 1, display: (this.state.currentUser == null)?"none":""}} disabled={this.state.showLoading_Login} size="small" variant="contained" onClick={this.onClick_Logout} fullWidth={true}>Ausloggen</Button>
                
                <div style={{display: (this.state.showLoading_Login == true)?"block":"none"}}>
                    <LinearProgress />
                </div>
            </TabPanel>

        
        </Paper>
        );
    }
}

export default LoginPanel;