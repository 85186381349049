import React from 'react';
import { Button, FormControlLabel, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import { firebase_app } from '../FirebaseInit';


import App from '../App';
import InfoIcon from '@mui/icons-material/Info';

interface IssueListPanelBackofficeProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}

interface IdentifiedDocumentPair {
    [key: string]: firebase.default.firestore.DocumentData;
 }

class IssueListPanelBackoffice extends React.Component<IssueListPanelBackofficeProps> {

   
    state = {

        showLoading_Login: false
    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;
    rows: IdentifiedDocumentPair;

    constructor(props: IssueListPanelBackofficeProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
        this.rows = {};
    }

    componentDidMount = () => { 

        this._processProps();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){

            let currentUser = await firebase_app.auth().currentUser;
            let unsubscribe = this.mainApp.userdataRef.doc(currentUser?.uid).onSnapshot(function(snapshot: any) {
            // ...
            });
          
            // Stop listening to changes
            unsubscribe();
            this._firebaseListenerInitialized = false;
        }

    }

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;
            console.log("_firebaseListenerInitialized", this._firebaseListenerInitialized);
            
            /*const documentReference = firebase_app
                                        .firestore()
                                        .collection('userdata').doc(currentUser?.uid).collection("issues");
            documentReference.onSnapshot(snapshot => {
                //console.log("snapshot", snapshot)
                snapshot.docChanges().forEach(change => {
                    if (change.type === "added") {
                        console.log("New data: ", change.doc.data(), change.doc.id);
                        //this.rows.push(change.doc.data());
                        this.rows[change.doc.id] = change.doc.data();
                    }
                    if (change.type === "modified") {
                        console.log("Modified data: ", change.doc.data(), change.doc.id);
                        this.rows[change.doc.id] = change.doc.data();
                    }
                    if (change.type === "removed") {
                        console.log("Removed data: ", change.doc.data(), change.doc.id);
                        delete this.rows[change.doc.id];
                    }
                    });

                    this.forceUpdate();
            });*/

            
            firebase_app.firestore().collectionGroup("issues").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    this.rows[doc.id] = doc.data();
                });
                this.forceUpdate();
            });


            

        }
    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: IssueListPanelBackofficeProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  




    render = () => {
        return (
        <Paper className={"minHeight-small"} style={{padding: "16px"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                <TableContainer component={Paper}>
                    <Table sx={{ }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Schadennummer</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Gemeldet</TableCell>
                            <TableCell colSpan={2}>Beschreibung</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>

                        {Object.keys(this.rows).sort((a, b) => this.rows[a].timestamp.seconds > this.rows[b].timestamp.seconds ? 1:-1).map((id) => (
                            <TableRow
                                key={id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >    
                            <TableCell>{this.rows[id].number}</TableCell>
                            <TableCell>{this.rows[id].email}</TableCell>
                            <TableCell>{new Date(this.rows[id].timestamp.seconds * 1000).toLocaleString()}</TableCell>
                            <TableCell>{this.rows[id].issueText}</TableCell>
                            <TableCell>
                                <Link to={("/backoffice/"+id)} key={id}>
                                    <InfoIcon></InfoIcon>
                                </Link></TableCell>
                            </TableRow>
                        ))}

                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>        
        </Paper>
        );
    }
}

export default IssueListPanelBackoffice;