import React from 'react';
import { Button, Grid, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { firebase_app } from '../FirebaseInit';
import seppOkSmall from '../assets/img/sepp-ok-small.png'
import seppFaqSmall from '../assets/img/sepp-faq-small.png'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

interface LoginVerificationPanelProps {
  style?: React.CSSProperties;
  balance?: string;
}

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://seppside.com',
    // This must be true.
    handleCodeInApp: true,
    dynamicLinkDomain: 'sepp.page.link'
  };


class LoginVerificationPanel extends React.Component<LoginVerificationPanelProps> {

    private login_emailTxt: React.RefObject<HTMLInputElement>;


    state = {
        tabValue: 0,
        currentUser: { 
            email: ""
        },
        email: ""
    }
    baseState: any;
    _stateInterval: any;


    constructor(props: LoginVerificationPanelProps) {
        super(props);

        this.baseState = this.state;
        this.login_emailTxt = React.createRef();

        // properties

    }

    componentDidMount = async () => { 

        // reinit auth if present
        firebase_app.auth();
        let currentUser = await firebase_app.auth().currentUser;

        //var email = window.localStorage.getItem('emailForSignIn');
        //var email = getCookie("loginEmail");
        //if(email?.length == 0){
        const urlParams = new URLSearchParams(window.location.search);
        var email = urlParams.get('mail')?.toString();
        //alert("using email from url parameter");
        //}

        this.setState({email: currentUser?.email});

        if (firebase_app.auth().isSignInWithEmailLink(window.location.href) && !currentUser) {
            if(email){
                firebase_app
                    .auth()
                    .signInWithEmailLink(email, window.location.href)
                    .then(function(result) {
                        //window.localStorage.removeItem('emailForSignIn');
                    })
                    .catch(function(error) {
                    alert(error);
                    });
            }else{
                alert("email not found in localstorage");
            }
        }
        

        this._startStatePolling();
    }

    componentWillUnmount = () => { 


    }

    _startStatePolling = () => {

        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval - 1000);
    }

    componentDidUpdate(props: LoginVerificationPanelProps) {
        //this.balance = props.balance;
        //this.setState({balance: this.balance});
    }

    checkState = async () => {          
        this.setState({currentUser: await firebase_app.auth().currentUser});        
    }  


    render = () => {
        return (
            <Grid container spacing={2} sx={{}}>
                <Grid item xs={12} md={8}>                          
                    <Typography style={{display: (this.state.currentUser == null)?"":"none"}} sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                        Deine E-Mail Adresse wird verifiziert...
                    </Typography>
                    <Typography style={{display: (this.state.currentUser != null)?"":"none"}} sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                        Wunderbar! Deine E-Mail wurde erfolgreich verifiziert:
                    </Typography>
                    <Typography sx={{ fontSize: 14, marginBottom: 2 }} color="text.primary" gutterBottom>
                        {this.state.currentUser?.email}
                    </Typography>

                    <div style={{display: (this.state.currentUser == null)?"block":"none"}}>
                        <LinearProgress />
                    </div>

                    <Button sx={{marginTop: 4}} size="large" variant="outlined" component={Link} to="/create" fullWidth={true}>Zur Meldungserfassung <ArrowRightAltIcon></ArrowRightAltIcon>   </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    {/* <MessagePanel mainApp={this}></MessagePanel> */}
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img className={""} style={{ width: "80%", display: (this.state.currentUser == null)?"":"none" }} src={seppFaqSmall} />
                        <img className={""} style={{ width: "80%", display: (this.state.currentUser != null)?"":"none" }} src={seppOkSmall} />
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default LoginVerificationPanel;