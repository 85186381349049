import React, {createRef } from 'react';
import { Button, FormControlLabel, Grid, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { firebase_app } from '../FirebaseInit';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import seppFaq from '../assets/img/sepp-faq.png'
import seppFaqSmall from '../assets/img/sepp-faq-small.png'

import App from '../App';

interface ContactPanelProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}

class ContactPanel extends React.Component<ContactPanelProps> {
   
    state = {


    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;

    constructor(props: ContactPanelProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
    }
 
    componentDidMount = () => { 

        this._processProps();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){


        }

    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: ContactPanelProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;

            //this._initChat(currentUser);
        }
    }

    onClick_Next = (event: any) => {
        

        //event.preventDefault();

    }


    render = () => {
        return (
        <Box className={"minHeight-small"} style={{padding: "0"}}>



<Typography className='contactHeader' sx={{ fontSize: 20, fontWeight: "bold" }} color="primary">
Kontakt und Impressum
</Typography> 
<div style={{padding: "8px", backgroundColor: "#ecf6f6"}}>
<Typography sx={{  fontWeight: "bold" }} color="primary">
CooPro GmbH
</Typography> 

<Typography sx={{ }} color="secondary">
Kronthaler Straße 2, 6330 Kufstein<br/><br/>

UID-Nr.: ATU71897904<br/>
Firmenbuch-Nr.: FN 507654 a Landesgericht Innsbruck<br/>
GISA-Zahl: 31698719<br/><br/>

Dein Spezialist für Schadenmanagement<br/><br/>

Telefon: +43 (0) 50 6330<br/>
E-Mail: <a href="mailto:info@seppside.com">info@seppside.com</a><br/>
</Typography> 

</div>
   
        </Box>
        );
    }
}

export default ContactPanel;