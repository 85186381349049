import React, {createRef } from 'react';
import { Button, FormControlLabel, IconButton, LinearProgress, List, ListItem, ListItemText, Modal, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Talk from "talkjs";
import { firebase_app } from '../FirebaseInit';

import CommentIcon from '@mui/icons-material/Comment';

import App from '../App';

interface MessagePanelProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}

const BackofficeUser = {
    id: "9wayPhkvSHbxueAH6ESfzR0klEB2",
    name: "seppside.com",
    email: "sepp@seppside.com",
    photoUrl: window.location.origin + '/sepp-avatar.png',
    role: "Member",
    info: "seppside.com Agent",
    welcomeMessage: "Herzlich Willkommen! Wie kann ich dir helfen? :-)"
}

const modalStyleMini = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "60%",
    maxWidth: "600px",
    maxHeight: "400px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "lightgrey",
    overflow: "auto",
    opacity: 0.95
  };


class MessagePanel extends React.Component<MessagePanelProps> {

   
    state = {

        showLoading_Login: false,
        talkJsSignature: "",
        conversationId: "",
        messageTemplatesModalOpen: false
    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;
    talkjsContainer: any;
    chatBox: any = null;

    constructor(props: MessagePanelProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
        this.talkjsContainer = React.createRef();
    }
 
    componentDidMount = () => { 

        this._processProps();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){


        }

    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: MessagePanelProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  

    _initChat = (firebaseUser: firebase.default.User) => {
        /* Retrieve the two users that will participate in the conversation */
        let currentUser = {
            id: firebaseUser.uid,
            name: firebaseUser.email as string,
            email: firebaseUser.email,
            //photoUrl: {seppAvatar},
            role: "Member",
            info: "...",
            welcomeMessage: "Servus!"
        };

        if(currentUser == null || BackofficeUser == null){return;}

        var getUserSignature = firebase_app.functions().httpsCallable('getUserSignature');       
        
        getUserSignature({  })
        .then((result: any) => {
            // Read result of the Cloud Function.
            //console.log("getUserSignature", result);
            this.setState({talkJsSignature: result.data.signature})

            /* Session initialization code */
            Talk.ready
            .then(() => {
                /* Create the two users that will participate in the conversation */
                const me = new Talk.User(BackofficeUser);

                /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
                //if (!window.talkSession) {
                    window.talkSession = new Talk.Session({
                        //appId: 'tK8krHQJ',
                        appId: 'ofdIwanw',
                        me: me,
                        signature: this.state.talkJsSignature
                    });
                //} 
                
                /* Create and mount chatbox in container */
                let chatbox = window.talkSession.createInbox({

                });
                chatbox.onConversationSelected(this._onConversationSelected);
                //chatbox.mount(this.talkjsContainer);
                chatbox.mount(this.talkjsContainer.current);

                this.chatBox = chatbox;
            })            
            .catch(e => console.error(e));

        });

    }

    _onConversationSelected = (conversation: any) => {
            console.log("conversationId", conversation.conversationId)
            this.setState({conversationId: conversation.conversationId});
    }

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;

            this._initChat(currentUser);
        }
    }

    _showTextTemplates = async () => {

        this.setState({messageTemplatesModalOpen: true});
    }

    _insertMessageTemplateText = (text: string) => {
        //const conversation = window.talkSession.getOrCreateConversation(this.state.conversationId);
        //console.log(conversation.messageField);
        this.chatBox.messageField.setText(text)
        //conversation.sendMessage("programmatically", {});
        this.setState({messageTemplatesModalOpen: false})
    }


    render = () => {
        return (
        <Box className={"minHeight-small"} style={{padding: "0"}}>
            <div style={{height: "730px"}} ref={this.talkjsContainer}></div>
            <Button sx={{marginBottom: 2, marginTop: 1, display: this.state.conversationId != null?"":"none"}}  variant='outlined' color="inherit" onClick={this._showTextTemplates} size="small" fullWidth={true}>Textvorlagen</Button>

            <Modal
                open={this.state.messageTemplatesModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={(event) => {this.setState({messageTemplatesModalOpen: false})}}
                disableEscapeKeyDown={false}
                >
                <Box sx={modalStyleMini}>

                    <List sx={{ width: '100%', bgcolor: 'background.paper', color: 'black' }}>
                    {[  "Vielen Dank für die Bilder. Kannst du mir bitte noch ein Foto von ….. schicken",
                        "Vielen Dank für die Unterlagen. Jetzt fehlt nur noch eine Kostenvoranschlag und ich kann loslegen.", 
                        "Vielen Dank für die Unterlagen, kannst du mir bitte noch die Neukaufrechnung schicken.",
                        "Vielen Dank für die Unterlagen für die Besichtigung durch einen Gutachter benötige ich deine Kontaktdaten bzw. von der Person die von dir damit beauftragt wird.",
                        "Vielen Dank für die Unterlagen. Unter welcher Telefonnummer darf dich mein Partnerhandwerker kontaktieren?",
                        "Vielen Dank für die Schadensmeldung. Kannst du mir noch sagen ……. das war.",
                        "Vielen Dank für die Schadensmeldung. Leider kann ich dir nicht weiterhelfen. Bitte wende dich direkt an die Versicherung.",
                        "Vielen Dank für die Schadensmeldung. Kannst du mir bitte die Daten des Geschädigten bekannt geben. (Name, Adresse, Geburtsdatum)",
                        "Vielen Dank, dass du dich für SEPP entschieden hast. Wenn dir meine Arbeit gefallen hat bewerte mich bei Google.",
                        "Vielen Dank, dass du dich für SEPP entschieden hast. Deine Meinung ist uns wichtig. Was würdest du verbessern?"].map((value) => (
                        <ListItem
                            key={value}
                            secondaryAction={
                                <IconButton onClick={(evt) => { this._insertMessageTemplateText(value)}} aria-label="comment">
                                <CommentIcon />
                                </IconButton>
                        }
                        >
                            <ListItemText primary={`${value}`} />
                        </ListItem>
                    ))}
                    </List>


                    <Button onClick={(event) => {this.setState({messageTemplatesModalOpen: false})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="outlined" fullWidth={true}>Zurück</Button>
                </Box>
            </Modal>
        </Box>
        );
    }
}

export default MessagePanel;