import React from 'react';
import { Button, FormControlLabel, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { firebase_app } from '../FirebaseInit';

interface LoginPanelProps {
  style?: React.CSSProperties;
  balance?: string;
}


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://seppside.com/postVerify',
    // This must be true.
    handleCodeInApp: true,
    dynamicLinkDomain: 'sepp.page.link'
  };

class AdminLoginPanel extends React.Component<LoginPanelProps> {

    private login_usernameTxt: React.RefObject<HTMLInputElement>;
    private login_passwordTxt: React.RefObject<HTMLInputElement>;

    private register_emailTxt: React.RefObject<HTMLInputElement>;
    private register_passwordTxt: React.RefObject<HTMLInputElement>;
    private register_password2Txt: React.RefObject<HTMLInputElement>;

    state = {
        tabValue: 1,
        currentUser: { 
            email: ""
        },
        showLoading_Login: false
    }
    baseState: any;
    _stateInterval: any;


    constructor(props: LoginPanelProps) {
        super(props);

        this.baseState = this.state;
        this.login_usernameTxt = React.createRef();
        this.login_passwordTxt = React.createRef();

        this.register_emailTxt = React.createRef();
        this.register_passwordTxt = React.createRef();
        this.register_password2Txt = React.createRef();

        // properties

    }

    componentDidMount = () => { 

        // reinit auth if present
        firebase_app.auth();

        var email = window.localStorage.getItem('emailForSignIn');

        if (firebase_app.auth().isSignInWithEmailLink(window.location.href)) {
         /* if (!email) {
            email = window.prompt('Please provide your email for confirmation');
          }*/

            if(email){
                firebase_app
                    .auth()
                    .signInWithEmailLink(email, window.location.href)
                    .then(function(result) {
                        //window.localStorage.removeItem('emailForSignIn');
                    })
                    .catch(function(error) {
                    alert(error);
                    });
            }
        }
        

        this._startStatePolling();
    }

    componentWillUnmount = () => { 


    }

    _startStatePolling = () => {

        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval - 1000);
    }

    componentDidUpdate(props: LoginPanelProps) {
        //this.balance = props.balance;
        //this.setState({balance: this.balance});
    }

    checkState = async () => {          
        this.setState({currentUser: await firebase_app.auth().currentUser});        
    }  

    tabHandleChange = (event: any, newValue: any) => {
        this.setState({tabValue: newValue});
    }
    
    onClick_CreateUser = () => {
        this.setState({showLoading_Login: true});
        const confirmationResult = firebase_app
                .auth()
                .createUserWithEmailAndPassword(this.register_emailTxt.current?.value as string, this.register_passwordTxt.current?.value as string).then((result) => {
                    
                    this.setState({showLoading_Login: false});

                    this.setState({tabValue: 1});
                    firebase_app.auth();
                    this._startStatePolling();

                }).catch((error) => {
                    this.setState({showLoading_Login: false});
                });

                console.log("confirmationResult", confirmationResult);
    }

    onClick_Logout = async () => {
        this.setState({showLoading_Login: true});
        await firebase_app.auth().signOut();
        this.setState({showLoading_Login: false});

        this._startStatePolling();
    }

    onClick_Login = async () => {
        if(this.login_usernameTxt.current == null || this.login_passwordTxt.current == null){
            return;
        }
        this.setState({showLoading_Login: true});
        await firebase_app.auth().signInWithEmailAndPassword(this.login_usernameTxt.current.value, this.login_passwordTxt.current.value).catch((error) => {
            window.alert(error);
        });
        this.setState({showLoading_Login: false});

        this._startStatePolling();
    }

    render = () => {
        return (
        <Paper style={{padding: "16px"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={this.state.tabValue} onChange={this.tabHandleChange} aria-label="basic tabs example">
                <Tab disabled={this.state.currentUser != null} label="Registrieren" />
                <Tab label="Einloggen" />
            </Tabs>
            </Box>
            <TabPanel value={this.state.tabValue} index={0}>
                <TextField inputRef={this.register_emailTxt} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} defaultValue={""} label={"E-Mail"} type="text" fullWidth={true}></TextField>
                <TextField inputRef={this.register_passwordTxt} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} defaultValue={""} label={"Passwort"} type="password" fullWidth={true}></TextField>
                <TextField inputRef={this.register_password2Txt} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} defaultValue={""} label={"Passwort wiederholen"} type="password" fullWidth={true}></TextField>

                <Button sx={{my: 1}} size="small" variant="contained" onClick={this.onClick_CreateUser} fullWidth={true}>Registrieren</Button>
                <div style={{display: (this.state.showLoading_Login == true)?"block":"none"}}>
                    <LinearProgress />
                </div>
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Angemeldet als
                </Typography>
                <Typography sx={{ fontSize: 14, marginBottom: 2 }} color="text.primary" gutterBottom>
                    {this.state.currentUser?.email}
                </Typography>

                <div style={{display: (this.state.currentUser == null)?"":"none"}}>
                    <TextField inputRef={this.login_usernameTxt} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} label={"Benutzername"} type="text" fullWidth={true}></TextField>
                    <TextField inputRef={this.login_passwordTxt} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} label={"Passwort"} type="password" fullWidth={true}></TextField>

                    <Button sx={{my: 1}} size="small" disabled={this.state.showLoading_Login} variant="contained" onClick={this.onClick_Login} fullWidth={true}>Einloggen</Button>
                </div>

                <Button sx={{my: 1, display: (this.state.currentUser == null)?"none":""}} disabled={this.state.showLoading_Login} size="small" variant="contained" onClick={this.onClick_Logout} fullWidth={true}>Ausloggen</Button>
                <Button sx={{marginTop: 4, marginBottom: 4, backgroundColor: "white", display: (this.state.currentUser == null)?"none":""}} size="large" variant="outlined" component={Link} to="/backoffice" fullWidth={true}>Weiter zum Backoffice </Button>
                <div style={{display: (this.state.showLoading_Login == true)?"block":"none"}}>
                    <LinearProgress />
                </div>
            </TabPanel>

        
        </Paper>
        );
    }
}

export default AdminLoginPanel;