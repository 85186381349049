import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import { Link } from "react-router-dom";
import logo from '../assets/img/sepp-logo-large-bg.png'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { firebase_app } from '../FirebaseInit';

interface AppBarProps {
  style?: React.CSSProperties;
  onClickLogin: any;
  onClickMenuItem: any;
}

export default function ButtonAppBar(props: AppBarProps) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const test = () => {
    var getUserSignature = firebase_app.functions().httpsCallable('getUserSignatureWithAppcheck');       
        
    getUserSignature({  }).then((result: any) => {
        console.log("getSignature", result.data.signature);
        alert("alles ok")
    }).catch((error) => {
      alert("fehler: " +error);
    });
  };

  return (
    <Box sx={{ flexGrow: 1/*, marginBottom: '150px' */}}>
      <AppBar position="relative" color="transparent" elevation={0}>
        <Toolbar sx={{alignItems: "start"}}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>

{/*           <Typography variant="h6" component="div" className="hideSmall" sx={{ width: "300px" }}>
            <img className={""} style={{ height: "180px", position: 'absolute' , marginLeft: "20px" }} src={logo} />
          </Typography>

            <Button component={Link} onClick={() => props.onClickMenuItem("start")} to="/" color="inherit" className='hideSmall appBarButton'>Start</Button>
          <Button component={Link} onClick={() => props.onClickMenuItem("create")} to="/create" color="inherit" className='hideSmall appBarButton'>Meldungserfassung</Button>
          <Button component={Link} onClick={() => props.onClickMenuItem("issues")} to="/issues" color="inherit" className='hideSmall appBarButton'>Meine Meldungen</Button>
          <Button component={Link} onClick={() => props.onClickMenuItem("faq")} to="/faq" color="inherit" className='hideSmall appBarButton'>FAQ</Button>
          <Button component={Link} onClick={() => props.onClickMenuItem("contact")} to="/contact" color="inherit" className='hideSmall appBarButton'>Kontakt</Button>
           

          <Typography variant="h6" component="div" className='hideLarge' sx={{ flexGrow: 1, alignItems: "center", display: "flex", justifyContent: "center" }}>
            <img className={""} style={{ boxShadow:'1px 1px 1px silver', height: "180px" }} src={logo} />
          </Typography>
*/}
          <Button startIcon={<AccountCircleIcon />} color="inherit" onClick={props.onClickLogin} className='appBarButton' style={{position:'absolute', 'right': 0}}>Login</Button>
        </Toolbar>
      </AppBar>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem component={Link} to="/" onClick={handleClose}>Start</MenuItem>
        <MenuItem component={Link} to="/create" onClick={handleClose}>Erfassung</MenuItem>
        <MenuItem component={Link} to="/issues" onClick={handleClose}>Meine Meldungen</MenuItem>
        <MenuItem component={Link} to="/faq" onClick={handleClose}>FAQ</MenuItem>
        <MenuItem component={Link} to="/contact" onClick={handleClose}>Kontakt</MenuItem>
        <MenuItem  onClick={test}>v0.678</MenuItem>
        {/* <MenuItem component={Link} to="/backoffice" onClick={handleClose}>Backoffice (GEHEIM)</MenuItem> */}
      </Menu>
    </Box>
  );
}