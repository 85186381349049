import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';

import reportWebVitals from './reportWebVitals';



import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3C8087',
      contrastText: '#000',
    },
    secondary: {
      main: '#37363b',
    },
    text: {
      primary: "#3C8087",
      secondary: "#3C8087"
    } /*,
    primary: {
      main: '#00abae',
      contrastText: '#fff',
    },
    secondary: {
      main: '#37363b',
    }*/
  },
});
/**brown #37363b */

ReactDOM.render(

      <ThemeProvider theme={darkTheme}>
        <App/>
      </ThemeProvider>
 ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
