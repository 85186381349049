import React, {createRef } from 'react';
import { Button, FormControlLabel, Grid, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { firebase_app } from '../FirebaseInit';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

import sepp01 from '../assets/img/sepp/Sepp01.png'
import ausrufezeichen from '../assets/img/ausrufezeichen.png'
import fragezeichen from '../assets/img/fragezeichen.png'
import Pfeil_rechts from '../assets/img/Pfeil_rechts.png'

import logo from '../assets/img/sepp-logo-large-bg.png'

import picto_questionmark from '../assets/img/picto/questionmark.png'
import picto_mobile from '../assets/img/picto/mobile.png'
import picto_conversation from '../assets/img/picto/conversation.png'
import picto_handshake from '../assets/img/picto/handshake.png'
import picto_repair from '../assets/img/picto/repair.png'
import picto_cash from '../assets/img/picto/cash.png'
import Carousel from 'react-material-ui-carousel'

import App from '../App';

interface FirstInfoPanelV2Props {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}


class FirstInfoPanelV2 extends React.Component<FirstInfoPanelV2Props> {

   
    state = {

        showLoading_Login: false
    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;

    constructor(props: FirstInfoPanelV2Props) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
    }
 
    componentDidMount = () => { 

        this._processProps();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){


        }

    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: FirstInfoPanelV2Props) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;

            //this._initChat(currentUser);
        }
    }

    onClick_Next = (event: any) => {
        

        //event.preventDefault();

    }


    render = () => {
        return (
        <Box className={"minHeight-small"} style={{padding: "0"}}>


            <Grid container spacing={2} >

                <Grid item xs={12} md={12}>
                    {/* Logo center */}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <img className={"mainLogoCenter"} style={{ }} src={logo} />
                    </Typography>

                </Grid>

                <Grid item xs={12} md={12} style={{display: 'flex', flexDirection:'row', justifyContent:'center'}}>
                    {/* create button */}
                    
                    <Button sx={{marginTop: 4, marginBottom: 4, backgroundColor: "white"}} size="large" variant="outlined" component={Link} to="/create" fullWidth={false}>meldung erfassen</Button>                    
                </Grid>

                <Grid item xs={12} md={12}>
                    {/* piktogramme */}
                        {/* large */}
                        <div className='pictoContainer hideSmallFlex' style={{}}>
                            <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_questionmark} />                                    
                                    </div>
                                    <div className='header'>
                                        1. Schadeneintritt
                                    </div>
                                    <div className='description'>
                                        Ein Schaden passiert.<br/>
                                        Was musst du tun?
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_mobile} />                                    
                                    </div>
                                    <div className='header'>
                                        2. Onlinemeldung
                                    </div>
                                    <div className='description'>
                                        Ganz bequem & einfach auf <br/>
                                        <strong>www.seppside.com</strong>
                                    </div>
                                </div>

                                <div className='pictoTile active'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_conversation} />                                    
                                    </div>
                                    <div className='header'>
                                        3. Beginn der Abwicklung
                                    </div>
                                    <div className='description'>
                                        Wir übernehmen die Bürokratie und kontaktieren deine Versicherung.
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_handshake} />                                    
                                    </div>
                                    <div className='header'>
                                        4. Reparaturfreigabe
                                    </div>
                                    <div className='description'>
                                        Wir klären die Reparaturfreigabe mit deiner Versicherung.
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_repair} />                                    
                                    </div>
                                    <div className='header'>
                                        5. Schadenregulierung
                                    </div>
                                    <div className='description'>
                                        Wir organisieren die Handwerker aus unserem Netzwerk.
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_cash} />                                    
                                    </div>
                                    <div className='header'>
                                        6. Abrechnung
                                    </div>
                                    <div className='description'>
                                        Wir rechnen alles mit deiner Versicherung ab!
                                    </div>
                                </div>
                        </div>

                        {/* small */}
                        <div className='pictoContainer hideLarge'>
                            <Carousel 
                                className='seppCarousel'
                                indicators={false} 
                                autoPlay={false} 
                                NextIcon={<EastIcon style={{fontSize: '64px'}}></EastIcon>} 
                                PrevIcon={<WestIcon style={{fontSize: '64px'}}></WestIcon>} 
                                navButtonsAlwaysVisible={true} 
                                animation='slide' 
                                navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                    style: {
                                        color: '#d7f1f0',
                                        
                                    },
                                    className: 'seppCarouselNavButton'
                                    
                                }} 
                                navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                    style: {
                                        color: '#d7f1f0'
                                    }
                                }}
                                >
                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_questionmark} />                                    
                                    </div>
                                    <div className='header'>
                                        1. Schadeneintritt
                                    </div>
                                    <div className='description'>
                                        Ein Schaden passiert.<br/>
                                        Was musst du tun?
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_mobile} />                                    
                                    </div>
                                    <div className='header'>
                                        2. Onlinemeldung
                                    </div>
                                    <div className='description'>
                                        Ganz bequem & einfach auf <br/>
                                        <strong>www.seppside.com</strong>
                                    </div>
                                </div>

                                <div className='pictoTile active'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_conversation} />                                    
                                    </div>
                                    <div className='header'>
                                        3. Beginn der Abwicklung
                                    </div>
                                    <div className='description'>
                                        Wir übernehmen die Bürokratie und kontaktieren deine Versicherung.
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_handshake} />                                    
                                    </div>
                                    <div className='header'>
                                        4. Reparaturfreigabe
                                    </div>
                                    <div className='description'>
                                        Wir klären die Reparaturfreigabe mit deiner Versicherung.
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_repair} />                                    
                                    </div>
                                    <div className='header'>
                                        5. Schadenregulierung
                                    </div>
                                    <div className='description'>
                                        Wir organisieren die Handwerker aus unserem Netzwerk.
                                    </div>
                                </div>

                                <div className='pictoTile'>
                                    <div className='imageContainer'>                                    
                                        <img className={""} src={picto_cash} />                                    
                                    </div>
                                    <div className='header'>
                                        6. Abrechnung
                                    </div>
                                    <div className='description'>
                                        Wir rechnen alles mit deiner Versicherung ab!
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    
                </Grid>

            </Grid>


            
   
        </Box>
        );
    }
}

export default FirstInfoPanelV2;