import React from 'react';
import { Button, FormControlLabel, LinearProgress, StandardTextFieldProps, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import moment from 'moment';
import Paper from '@mui/material/Paper';
import { firebase_app } from '../FirebaseInit';


import App from '../App';
import DropzoneArea from 'mui-file-dropzone/dist/components/DropzoneArea';


interface IssueViewPanelProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}

interface IdentifiedDocumentPair {
    [key: string]: firebase.default.firestore.DocumentData;
 }

class IssueViewPanel extends React.Component<IssueViewPanelProps> {

   
    state = {
        email: "", 
        firstname: "",
        lastname: "",
        mobile: "",
        policynumber: "",
        insuranceagency: "",
        files: [],
        gender: "",
        issueDate: moment().format("YYYY-MM-DD"),
        birthDate: "",
        issueText: "",
        craftsmanService: false,
        imageLinks: [],
        issueLocation: "",

        additionalText: "",

        showLoading_Login: false
    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;
    rows: IdentifiedDocumentPair;

    issueId: string = "ECoIQZQS4CcsTI4PHb7Q";

    constructor(props: IssueViewPanelProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
        this.rows = {};
    }

    componentDidMount = () => { 
        let params = window.location.href.split("/");
        if(params.length >= 4){
            this.issueId = params[4];
        }

        this._processProps();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){

            let currentUser = await firebase_app.auth().currentUser;
            let unsubscribe = this.mainApp.userdataRef.doc(currentUser?.uid).onSnapshot(function(snapshot: any) {
            // ...
            });
          
            // Stop listening to changes
            unsubscribe();
            this._firebaseListenerInitialized = false;
        }

    }

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;
            //console.log("_firebaseListenerInitialized", this._firebaseListenerInitialized);

            //console.log("query for ", this.issueId)
            // issue details
            firebase_app.firestore().collectionGroup("issues").where("userId", "==", currentUser.uid).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    
                    if(doc.id == this.issueId){
                        
                        //console.log("direct found", doc.id, " => ", doc.data());
                        let data = doc.data();

                        this.setState({
                            email:  data["email"],
                            firstname:  data["firstname"],
                            lastname:  data["lastname"],
                            issueText:  data["issueText"],
                            issueLocation:  data["issueLocation"],
                            birthDate:  data["birthDate"],
                            gender:  data["gender"],
                            mobile:  data["mobile"],
                            policynumber:  data["policynumber"],
                            insuranceagency:  data["insuranceagency"],
                            issueDate:  data["issueDate"],
                            craftsmanService:  data["craftsmanService"],
                        });

                        this.getImages(data["userId"]);
                    }
                });
                this.forceUpdate();
            });

            this._loadAdditions();
            
        }
    }

    _loadAdditions = async () => {
        let currentUser = await firebase_app.auth().currentUser;
        if(currentUser){
            firebase_app.firestore().collectionGroup("additions").where("userId", "==", currentUser.uid).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    
                    let data = doc.data();
                    if(data["issueId"] == this.issueId){
                        //console.log("additions found", doc.id, " => ", doc.data());
                        this.rows[doc.id] = doc.data();
                    }
                    
                    
                });
                this.forceUpdate();
            });
        }
    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  
    
    componentDidUpdate(prevProps: IssueViewPanelProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }



    getImages = (userId: string) => {
        

        const fetchImages = async () => {
            var storage = firebase_app.storage();
            //let userId = await firebase_app.auth().currentUser?.uid;

            let result = await storage.ref().child(`images/${userId}/${this.issueId}`).listAll();
            console.log(result);
            let urlPromises = result.items.map((imageRef) =>
              imageRef.getDownloadURL()
            );
      
            return Promise.all(urlPromises);
          };
      
          const loadImages = async () => {
            const urls = await fetchImages();
            console.log(urls)
            this.setState({imageLinks: urls});
          };
          loadImages();
      
    }

    onClick_CreateAdditionalText = async () => {
        let currentUser = await firebase_app.auth().currentUser;

        let userDoc = firebase_app
        .firestore()
        .collection('userdata');

        const documentReference = userDoc.doc(currentUser?.uid);  

        let result = documentReference.collection('issues/'+this.issueId+'/additions').add({ 
            text: this.state.additionalText,
            timestamp: new Date(),
            issueId: this.issueId,
            userId: currentUser?.uid
        }).then(result => {
            console.log("result", result);
            // create was successful. now upload documents
            this.setState({
                showLoading_Login: false,
                additionalText: "",
            });
            this._loadAdditions();
    
            }).catch(error => {
                console.log("error", error);
                this.setState({showLoading_Login: false});
            });
    }



    render = () => {
        return (
        <Paper className={"minHeight-small"} style={{padding: "16px"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                <Typography sx={{ fontSize: 16, marginBottom: 1 }} color="secondary" gutterBottom>
                    Deine Kontaktdaten
                </Typography> 

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                <span style={{color: 'gray'}}>E-Mail:</span>
                            </Typography>                            
                        </td>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                {this.state.email}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                <span style={{color: 'gray'}}>Name:</span>
                            </Typography>                            
                        </td>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                            {this.state.firstname} {this.state.lastname}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                <span style={{color: 'gray'}}>Geburtsdatum:</span>
                            </Typography>                            
                        </td>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                            {this.state.birthDate}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                <span style={{color: 'gray'}}>Geschlecht:</span>
                            </Typography>                            
                        </td>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                            {this.state.gender}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                <span style={{color: 'gray'}}>Mobiltelefon:</span>
                            </Typography>                            
                        </td>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                            {this.state.mobile}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                <span style={{color: 'gray'}}>Polizzennummer:</span>
                            </Typography>                            
                        </td>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                            {this.state.policynumber}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                <span style={{color: 'gray'}}>Versicherungsgesellschaft:</span>
                            </Typography>                            
                        </td>
                        <td>
                            <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                            {this.state.insuranceagency}
                            </Typography>
                        </td>
                    </tr>
                    </tbody>
                </table>
                
                <Typography sx={{ fontSize: 16, marginBottom: 1, marginTop: 3 }} color="secondary" gutterBottom>
                    Schadenmeldung
                </Typography> 
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    <span style={{color: 'gray'}}>Vorfallsdatum</span>
                                </Typography>                            
                            </td>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    {this.state.issueDate}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    <span style={{color: 'gray'}}>Handwerkerservice gewünscht</span>
                                </Typography>                            
                            </td>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    {this.state.craftsmanService == true?"ja":"nein"}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    <span style={{color: 'gray'}}>Vorfallsort</span>
                                </Typography>                            
                            </td>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    {this.state.issueLocation}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    <span style={{color: 'gray'}}>Beschreibung</span>
                                </Typography>                            
                            </td>
                            <td>
                                <Typography sx={{ fontSize: 12}} color="primary" gutterBottom>
                                    {this.state.issueText}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Typography sx={{ fontSize: 16, marginBottom: 1, marginTop: 3 }} color="secondary" gutterBottom>
                    Bilder
                </Typography> 
                {Object.keys(this.state.imageLinks).map((index) => (
                            <img style={{maxHeight: '100px', maxWidth: '100px' }} key={index} src={this.state.imageLinks[parseInt(index)]}></img>
                        ))}
            </Box>    

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Typography sx={{ fontSize: 16, marginBottom: 1, marginTop: 3 }} color="secondary" gutterBottom>
                    Ergänzungen
                </Typography> 


                <TableContainer component={Paper}>
                    <Table sx={{ }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Erfasst</TableCell>
                            <TableCell>Text</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>

                        {Object.keys(this.rows).sort((a, b) => this.rows[a].timestamp.seconds > this.rows[b].timestamp.seconds ? 1:-1).map((id) => (
                            <TableRow
                                key={id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >    
                            <TableCell>
 
                                {new Date(this.rows[id].timestamp.seconds * 1000).toLocaleString()}

                            </TableCell>

                            <TableCell style={{whiteSpace: 'pre-wrap'}}>
                                    {this.rows[id].text}
                            </TableCell>
                             </TableRow>
                        ))}

                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography sx={{ fontSize: 12, marginTop: 3}} color="primary" gutterBottom>
                    Du hast noch etwas vergessen? Füge weiteren Text hinzu.
                </Typography>

                {/* <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1, marginTop: 2}}>
                Bitte fotografiere den Schaden so genau wie möglich und lade alle relevanten Dokumente hoch.<br/>
                z.B.: Ankaufsrechnung, Kostenvoranschläge, Fotos (von allen Seiten)<br/>
                </Typography>
                <DropzoneArea  
                    inputProps={{ disabled: false }}        

                    fileObjects={[]}
                    initialFiles={this.state.files}

                    dropzoneText='Fotos und andere wichtige Dateien hier hochladen'
                    maxFileSize={(30*1024*1024)} 
                /> */}

                <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1, marginTop: 2}}>
                Zusätzlichen Text bitte hier eingeben
                </Typography>
                <TextField value={this.state.additionalText} onChange={(evt) => {this.setState({additionalText: evt.target.value})}} disabled={false} multiline={true} sx={{ fontSize: 14, marginBottom: 2 }} rows={10} inputProps={{}} placeholder='Bitte beschreibe den Schaden so genau wie möglich. Nimm dir ein bisschen Zeit. Je genauer du den Schaden beschreibst, desto schneller bekommst du deinen Schotter ;-)' label={"Beschreibung"} type="text" fullWidth={true}></TextField>
                <Button sx={{marginTop: 1, backgroundColor: "white"}} size="large" variant="outlined" onClick={this.onClick_CreateAdditionalText} fullWidth={true}>
                    Ergänzung speichern                      
                </Button>
            </Box>    
        </Paper>
        );
    }
}

export default IssueViewPanel;