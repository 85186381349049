import React from 'react';
import './App.css';


import { styled } from '@mui/material/styles';
import { Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextareaAutosize, Typography } from '@mui/material';

import { firebase_app } from './FirebaseInit';
import '@firebase/firestore-compat';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Countdown from "react-countdown";
import LoginPanel from './components/LoginPanel';
import IssueListPanel from './components/IssueListPanel';
import MessagePanel from './components/MessagePanel';

import CreateIssuePanel from './components/CreateIssuePanel';
import ButtonAppBar from './components/AppBar';

import { BrowserRouter, Routes, Route, Location } from "react-router-dom";
import IssueViewPanel from './components/IssueViewPanel';
import MessagePanelBackoffice from './components/MessagePanelBackoffice';
import FirstInfoPanel from './components/FirstInfoPanel';
import FaqPanel from './components/FaqPanel';

import sepp from "./assets/img/sepp-1.png";
import seppHi from "./assets/img/sepp-hi.png";
import LoginVerificationPanel from './components/LoginVerificationPanel';
import IssueListPanelBackoffice from './components/IssueListPanelBackoffice';
import AdminLoginPanel from './components/AdminLoginPanel';
import IssueViewPanelBackoffice from './components/IssueViewPanelBackoffice';
import ContactPanel from './components/ContactPanel';
import Guide from './components/Guide';
import FirstInfoPanelV2 from './components/FirstInfoPanelV2';


declare global { 
  interface Window { 
    dataRefreshInterval: number;
    talkSession: any;
  }
}

interface AppProps {
  style?: React.CSSProperties;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.h3,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const modalStyle = {

  width: "80%",
  margin: 'auto',
  overflow: "auto",
  opacity: 0.95
};

window.dataRefreshInterval = 3000;


class App extends React.Component<AppProps> {
  
  state = {
    loginModalOpen: false,
    countdownVisible: false,
    bgClass: "bg1"
  }

  userdataRef: firebase.default.firestore.CollectionReference<firebase.default.firestore.DocumentData>;

  baseState: any;
  _stateInterval: any;

  constructor(props: AppProps) {
    super(props);

    this.userdataRef = firebase_app
                        .firestore()
                        .collection('userdata');

    this.baseState = this.state;
    // properties

  }

  private _modalInterval: any = null;
  private CountDownDate: Date = new Date();
  _firebaseListenerInitialized: boolean = false;

  componentDidMount = () => { 


    //const urlParams = new URLSearchParams(window.location.search);
    /*let unlock = urlParams.get('unlock');
    if(unlock != "true"){
      this._modalInterval = setInterval(this.handleModal, 1000);
    }else{
      this.state.countdownVisible = false;
    } */   
      
    this._startStatePolling();
  }

  componentWillUnmount = () => { 
    
  }

  handleModal = () => {
    let countDownDateUTC = new Date();
    countDownDateUTC.setUTCDate(29);
    countDownDateUTC.setUTCMonth(11);
    countDownDateUTC.setUTCFullYear(2021);
    countDownDateUTC.setUTCHours(14);
    countDownDateUTC.setUTCMinutes(0);
    countDownDateUTC.setUTCSeconds(0);

    this.CountDownDate = countDownDateUTC;

    if((new Date()).getTime() < countDownDateUTC.getTime()){
      this.setModalOpen();
      this.setState({countdownVisible: true});
    }else{
      if(this._modalInterval != null ){
        clearInterval(this._modalInterval);
        this._modalInterval = null;
        this.setState({countdownVisible: false});
        this.setModalClose();
      }
    }
  }

  _startFirebaseListener = async () => {
      if(this._firebaseListenerInitialized == true){
          let currentUser = await firebase_app.auth().currentUser;
          if(!currentUser){
              this._firebaseListenerInitialized = false;
              return;
          }
          return;
      }

      let currentUser = await firebase_app.auth().currentUser;
      if(currentUser){
          this._firebaseListenerInitialized = true;
          //console.log("_firebaseListenerInitialized", this._firebaseListenerInitialized);         

      }
  }

  _startStatePolling = () => {
      this.checkState();
      if(this._stateInterval){
          clearInterval(this._stateInterval);
      }
      this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
  }

  checkState = async () => {  
      this._startFirebaseListener();
      this.onClick_MenuItem("dummy");
  }  


  setModalOpen = () => {
    this.setState({loginModalOpen: true});
  }

  setModalClose = () => {
    clearInterval(this._modalInterval);
    this._modalInterval = null;
    this.setState({loginModalOpen: false});
  }


  onClick_MenuItem = (selectedItem: string) => {
    //let selectedItem = arguments[0];
    let item = window.location.href;
    if(selectedItem != "dummy"){
      item = selectedItem;
    }

    if( item.indexOf("create") > -1 ||
        item.indexOf("issues") > -1) {

        this.setState({bgClass: "bg2"});
    }else{
      this.setState({bgClass: "bg1"});
    }
  }
  



  //  Main Layout
  //
  render = () => {
    return (      
        <Box className={this.state.bgClass}>
          <BrowserRouter>
            <ButtonAppBar onClickLogin={this.setModalOpen} onClickMenuItem={this.onClick_MenuItem} ></ButtonAppBar>
            <Box sx={{ flexGrow: 1 }} style={{width: "90%", margin: "auto", marginTop: "0px"}}>
    
            

                <Routes>
                    <Route path="/" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={12}>
                          <FirstInfoPanelV2 mainApp={this}></FirstInfoPanelV2>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/create" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={8}>
                          <CreateIssuePanel mainApp={this}></CreateIssuePanel>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          
                          {/* <MessagePanel mainApp={this}></MessagePanel> */}
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img className={"hideSmall"} style={{ maxWidth: "600px" }} src={seppHi} />
                          </div>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/postVerify" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={12}>                          
                          <LoginVerificationPanel></LoginVerificationPanel>  
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/backoffice" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={6}>
                          Alle Meldungen<br/><br/>
                          <IssueListPanelBackoffice mainApp={this}></IssueListPanelBackoffice>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          Aktuelle Chats<br/><br/>
                          <MessagePanelBackoffice mainApp={this}></MessagePanelBackoffice>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/backofficelogin" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={12}>
                          <AdminLoginPanel></AdminLoginPanel>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/chat" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={12}>
                          <MessagePanel mainApp={this}></MessagePanel>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/contact" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={12}>
                          <ContactPanel mainApp={this}></ContactPanel>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/faq" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={12}>
                          <FaqPanel mainApp={this}></FaqPanel>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/issues" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={6}>
                          <IssueListPanel mainApp={this}></IssueListPanel>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MessagePanel mainApp={this}></MessagePanel>
                        </Grid>
                      </Grid> } >
                    </Route>
                    <Route path="/issues/:issueId" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={6}>
                          <IssueViewPanel mainApp={this}></IssueViewPanel>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MessagePanel mainApp={this}></MessagePanel>
                        </Grid>
                      </Grid> } 
                      />
                    <Route path="/backoffice/:issueId" element={<Grid id="mainApp" container spacing={2} sx={{display: this.state.countdownVisible? "none":""}}>
                        <Grid item xs={12} md={12}>
                          <IssueViewPanelBackoffice mainApp={this}></IssueViewPanelBackoffice>
                        </Grid>

                      </Grid> } 
                      />
                </Routes>


            

                
                

                <Modal
                  open={this.state.loginModalOpen}
                  onClose={this.setModalClose} 
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  disableEscapeKeyDown={false}

                >
                  <Box sx={modalStyle}>   
          
                          <LoginPanel></LoginPanel>             
      
                  </Box>
                </Modal>
            </Box>   
          </BrowserRouter> 
        </Box>  
    );
  }
}

export default App;
