import React, {createRef } from 'react';
import { Button, FormControlLabel, LinearProgress, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Talk from "talkjs";
import { firebase_app } from '../FirebaseInit';



import App from '../App';

interface MessagePanelIssueDetailProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
  issueId: string;
}

/*const BackofficeUser = {
    id: "mwKDsdIs1ya6XEJMin542tXmDWD3",
    name: "SEPP (08:00 - 17:00)",
    email: "sepp@seppsite.com",
    photoUrl: window.location.origin + '/sepp-avatar.png',
    role: "Member",
    info: "seppsite.com Agent",
    welcomeMessage: "Herzlich Willkommen! Wie kann ich dir helfen? :-)"
}*/

const BackofficeUser = {
    id: "9wayPhkvSHbxueAH6ESfzR0klEB2",
    name: "seppside.com",
    email: "sepp@seppside.com",
    photoUrl: window.location.origin + '/sepp-avatar.png',
    role: "Member",
    info: "seppside.com Agent",
    welcomeMessage: "Herzlich Willkommen! Wie kann ich dir helfen? :-)"
}


class MessagePanelIssueDetail extends React.Component<MessagePanelIssueDetailProps> {

   
    state = {

        showLoading_Login: false,
        talkJsSignature: ""
    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;
    talkjsContainer: any;
    issueId: string;

    constructor(props: MessagePanelIssueDetailProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
        this.talkjsContainer = React.createRef();
        this.issueId = props.issueId;
    }
 
    componentDidMount = () => { 

        this._processProps();
        this._startStatePolling();

    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){


        }

    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: MessagePanelIssueDetailProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
        this.issueId = this.props.issueId;

        console.log("backoffice msg panel issueId", this.issueId);
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  

    _initChat = (issueUser: any) => {
        //console.log("init chat", firebaseUser.uid);
        /* Retrieve the two users that will participate in the conversation */
    
        if(issueUser == null || BackofficeUser == null){return;}

        var getUserSignature = firebase_app.functions().httpsCallable('getUserSignature');       
        
        getUserSignature({  })
        .then((result: any) => {
            // Read result of the Cloud Function.
            //console.log("getUserSignature", result);
            this.setState({talkJsSignature: result.data.signature})


            /* Session initialization code */
            Talk.ready
            .then(() => {
                /* Create the two users that will participate in the conversation */
                const me = new Talk.User(BackofficeUser);
                const other = new Talk.User(issueUser);

                /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
                //if (!window.talkSession) {
                    console.log("talkJsSignature", this.state.talkJsSignature);
                    window.talkSession = new Talk.Session({
                        appId: 'ofdIwanw',
                        //appId: 'tK8krHQJ',
                        me: me,
                        signature: this.state.talkJsSignature
                    });
                //} 
                
                /* Get a conversation ID or create one */
                const conversationId = Talk.oneOnOneId(me, other);
                const conversation = window.talkSession.getOrCreateConversation(conversationId);
                
                /* Set participants of the conversations */
                conversation.setParticipant(me);
                conversation.setParticipant(other);

                /* Create and mount chatbox in container */
                let chatbox = window.talkSession.createChatbox(conversation);
                //chatbox.mount(this.talkjsContainer);
                chatbox.mount(this.talkjsContainer.current);
            })            
            .catch(e => console.error(e));
        });
    }

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser && this.issueId != ""){
            this._firebaseListenerInitialized = true;

            firebase_app.firestore().collectionGroup("issues").where("issueId", "==", this.issueId).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log("direct found", doc.id, " => ", doc.data());
                    //this.rows[doc.id] = doc.data();
                    let data = doc.data();

                    this._initChat({
                        id: data["userId"],
                        name: data["email"] as string,
                        email: data["email"] as string,
                        //photoUrl: "https://randomuser.me/api/portraits/men/32.jpg",
                        role: "Member",
                        info: "...",
                        welcomeMessage: "Servus!"
                    });

                });
                
                
            });

            
        }
    }



    render = () => {
        return (
        <Box className={"minHeight-small"} style={{padding: "0"}}>

            <div style={{height: "730px"}} ref={this.talkjsContainer}></div>

   
        </Box>
        );
    }
}

export default MessagePanelIssueDetail;